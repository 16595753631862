<template>
  <div>
    <label for="form-label" v-if="!onlyShow"> Ingresa la Dirección </label>
    <div class="div-icon-autocomplete" v-if="!onlyShow">
      <div class="inputContainer mb-3" style="width: 100%; height: 15px">
        <gmap-autocomplete
          class="input__text-map"
          :value="actualTextInAutocomplete"
          @place_changed="setPlace"
          @click="deleteTextInAutocomplete"
          :options="{
            fields: ['geometry', 'address_components', 'formatted_address']
          }"
        >
        </gmap-autocomplete>
      </div>
      <br />
    </div>
    <br />
    <GmapMap
      :center="center"
      :zoom="12"
      map-style-id="roadmap"
      :options="mapOptions"
      style="width: 100%; height: 300px"
      ref="mapRef"
      @click="handleMapClick"
    >
      <GmapMarker
        :position="marker.position"
        :clickable="true"
        :draggable="true"
        @drag="handleMarkerDrag"
        @click="panToMarker"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'MapMarker',
  data() {
    return {
      //Autocomplete
      actualTextInAutocomplete: '',
      inputIsOk: false,
      currentPlace: null,

      marker: { position: { lat: -33.447487, lng: -70.673676 } },
      center: { lat: -33.447487, lng: -70.673676 },
      mapOptions: {
        disableDefaultUI: true
      }
    }
  },
  props: {
    onlyShow: {
      type: Boolean,
      default: false
    },
    positionObj: {
      default: function () {
        return { lat: -33.447487, lng: -70.673676 }
      }
    },
    textInput: {
      type: String,
      default: ''
    }
  },
  watch: {
    positionObj(val) {
      this.center = val
      this.marker.position = val
      this.panToMarker()
    },
    textInput(val) {
      this.actualTextInAutocomplete = val
    }
  },
  mounted() {
    this.geolocate()
  },
  methods: {
    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        this.panToMarker()
        //emit para componente padre.
        this.$emit('geoDataOnlyLatAndLng', {
          latitude: this.marker.position.lat,
          length: this.marker.position.lng
        })
      })
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position)
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    },

    /****** Autocomplete methods ******/

    //delete Autocomplete text
    deleteTextInAutocomplete() {
      this.inputIsOk = false
      this.actualTextInAutocomplete = ''
    },

    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place
      let geoData = {} //array de objetos {field:"country",value:"Chile"}

      if (this.currentPlace) {
        this.inputIsOk = true
        if (this.currentPlace.geometry && this.currentPlace.geometry.location) {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          }
          this.marker = { position: marker }
          this.panToMarker()

          this.center = marker
          this.actualTextInAutocomplete = this.currentPlace.formatted_address
          //emit array de objetos de location y address_components del place

          //push latitude and longitude
          geoData.latitude = marker.lat
          geoData.length = marker.lng
        }
        //push values from address components like street_number or administrative_area_level_1
        if (this.currentPlace.address_components) {
          for (const cmpnt of this.currentPlace.address_components) {
            geoData[cmpnt.types[0]] = cmpnt.long_name
          }
        }

        if (this.currentPlace.formatted_address) {
          geoData.complete_address = this.currentPlace.formatted_address
        }
        //emit para componente padre.
        this.$emit('geoData', geoData)
      }
    }
  },
  computed: {
    textAutoCompleteMap() {
      return this.actualTextInAutocomplete
    }
  }
}
</script>

<style>
.pac-container {
  z-index: 10000 !important;
  margin-top: -6.8rem;
}
.div-icon-autocomplete {
  display: flex;
  flex-direction: row;
}

.pac-target-input {
  width: 100%;
  height: 25px;
  font-size: 13px;
  border: 1px solid #c2c2c2;
  padding: 15px;
}

.inputMalo {
  font-size: 10px;
  color: red;
}

.inputBueno {
  font-size: 10px;
  color: green;
}
</style>
